import { Box, HStack, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PaginatedTable } from "../../../components/common";
import ExportReport from "../../../pages/(dashboard-routes)/reports/ExportReport";
import { fetchLeadProviderReport } from "../../../utils/api/reports";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import DatePicker from "../../common/DateRangePicker";
import { defaultFilterValues } from "../../Drawers/SearchAndFilterDrawer/utils";

const LeadProviderReport = () => {
  const dId = useUserInfo("dealership")?.id;
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState<any>(20);
  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    selected: false,
  });
  const { data, isLoading } = useQuery({
    queryKey: [
      "fetchLeadProviderReport",
      dId,
      selectionRange?.startDate,
      selectionRange?.endDate,
      page,
      perPage,
    ],
    queryFn: fetchLeadProviderReport,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const tableHeader = [
    { id: 1, value: "Lead Sources", label: "leadSources" },
    { id: 2, value: "# Of Leads", label: "noOfLeads" },
    { id: 3, value: "Engaged Rate", label: "engagedRate" },
    { id: 4, value: "Invalid", label: "invalid" },
    { id: 5, value: "Duplicate", label: "duplicate" },
    { id: 5, value: "Sold", label: "sold" },
  ];

  const tableData = data?.data?.data?.map((item: any) => ({
    leadSources: item["Lead Sources"],
    noOfLeads: (
      <Text
        color="var(--primary-600)"
        cursor="pointer"
        onClick={() => {
          const leadSource = {
            value: item["lead_source_id"],
            label: item["Lead Sources"],
          };
          const valuesWithLeadSource = {
            ...defaultFilterValues,
            filter_and_sort: {
              ...defaultFilterValues.filter_and_sort,
              date_range: {
                start_date: selectionRange?.startDate,
                end_date: selectionRange?.endDate,
              },
              created_date: true,
              datePicked: true,
              contact_attributes: {
                ...defaultFilterValues.filter_and_sort.contact_attributes,
                lead_source: [leadSource],
              },
            },
          };

          localStorage.setItem(
            "search_and_filter",
            JSON.stringify(valuesWithLeadSource.filter_and_sort)
          );

          const url = `/leads/lead-details`;
          navigate(url);
        }}
      >
        {item["# Of Leads"]}
      </Text>
    ),
    engagedRate: item["Engaged Rate"],
    invalid: item["Invalid"],
    duplicate: item["Duplicate Leads"],
    sold: item["Sold"],
  }));

  return (
    <Box>
      <HStack justifyContent="space-between" mb="1rem">
        <Box>
          <DatePicker
            value={selectionRange}
            onChange={(value) => {
              setSelectionRange(value);
              setPage(1);
            }}
            w="100%"
          />
        </Box>
        <ExportReport reportType="lead_provider" />
      </HStack>
      <Text textStyle="caption" color="var(--grey-600)" mb="1rem">
        Found {data?.data?.total_count}
      </Text>
      <PaginatedTable
        header={tableHeader}
        data={tableData}
        maxPageNumbers={3}
        isPaginated={true}
        hasMultiBody={false}
        itemsPerPage={perPage}
        perPageChange={(value) => {
          setPerPage(Number(value?.label));
          setPage(1);
        }}
        currentPage={page}
        setCurrentPage={setPage}
        rowCount={data?.data?.total_count}
        isLoadingData={isLoading}
      />
    </Box>
  );
};

export default LeadProviderReport;
