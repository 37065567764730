import { HStack, Text, VStack } from "@chakra-ui/react";
import moment from "moment";
import { fmtHMS } from "../../../../../utils";
import { SalesLeadIcon } from "../../../../icons/svg";
import Activity from "../History/Activity/Activity";

const SingleLeadCard = ({ item, index, setClickedRecord,details }: any) => {
  return (
    <Activity
      key={item?.id}
      id={item?.id}
      handleClick={() => setClickedRecord(details)}
      title={"Active Sales Lead"}
      dateAndTime={
        item?.created_at
          ? moment(item?.created_at).format("dddd MMM DD YY • hh:mm a")
          : "-"
      }
      icon={<SalesLeadIcon />}
      content={
        <HStack
          width="100%"
          pt="10px"
          alignItems="end"
          justifyContent="space-between"
        >
          <VStack alignItems="flex-start">
            {item?.lead_source && (
              <Text textStyle="h6" fontWeight="400">
                Lead Source: {item?.lead_source}
              </Text>
            )}

            <HStack gap="20px">
              {item?.time_to_first_response && (
                <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                  {fmtHMS(Number(item?.time_to_first_response))}
                </Text>
              )}
            </HStack>
            <HStack>
              {" "}
              <Text textStyle="h6" fontWeight="400">
                {item?.vehicle_details?.year ?? item?.year}{" "}
                {item?.vehicle_details?.make ?? item?.make}{" "}
                {item?.vehicle_details?.model ?? item?.model
                  ? `- ${item?.vehicle_details?.model ?? item?.model}`
                  : ""}
              </Text>{" "}
            </HStack>
          </VStack>
        </HStack>
      }
    />
  );
};

export default SingleLeadCard;
